define("ember-cli-facebook-js-sdk/fb", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  var fb = {
    fbInitPromise: null,

    // Core Methods

    init: function init(settings) {
      this.fbInitSettings = this.fbInitSettings || settings;
      if (this.fbInitPromise) {
        return this.fbInitPromise;
      }

      var original = window.fbAsyncInit;
      var initSettings = this.fbInitSettings;
      if (!initSettings || !initSettings.appId || !initSettings.version) {
        return _ember["default"].RSVP.reject("No settings for init");
      }

      this.fbInitPromise = new _ember["default"].RSVP.Promise(function (resolve) {
        window.fbAsyncInit = function () {
          window.FB.init(initSettings);
          _ember["default"].run(null, resolve);
        };
        _ember["default"].$.getScript("//connect.facebook.net/en_US/sdk.js");
      }).then(function () {
        if (original) {
          window.fbAsyncInit = original;
          window.fbAsyncInit();
          window.fbAsyncInit.hasRun = true;
        }
      });

      return this.fbInitPromise;
    },

    api: function api(path) {
      var method = "GET";
      var parameters = {};
      var arg;

      if (!path) {
        return _ember["default"].RSVP.reject("Please, provide a path for your request");
      }

      switch (arguments.length) {
        case 2:
          arg = arguments[1];
          if (typeof arg === "string") {
            method = arg;
          } else {
            parameters = arg;
          }
          break;
        case 3:
          method = arguments[1];
          parameters = arguments[2];
      }

      parameters = _ember["default"].$.extend(parameters, { access_token: this.accessToken });

      return this.init().then(function () {
        return new _ember["default"].RSVP.Promise(function (resolve, reject) {
          FB.api(path, method, parameters, function (response) {
            if (response.error) {
              _ember["default"].run(null, reject, response.error);
              return;
            }

            _ember["default"].run(null, resolve, response);
          });
        });
      });
    },

    ui: function ui(params) {
      return this.init().then(function () {
        return new _ember["default"].RSVP.Promise(function (resolve, reject) {
          FB.ui(params, function (response) {
            if (response && !response.error_code) {
              _ember["default"].run(null, resolve, response);
              return;
            }

            _ember["default"].run(null, reject, response);
          });
        });
      });
    },

    // Facebook Login Methods

    getLoginStatus: function getLoginStatus(forceRequest) {
      return this.init().then(function () {
        return new _ember["default"].RSVP.Promise(function (resolve) {
          FB.getLoginStatus(function (response) {
            _ember["default"].run(null, resolve, response);
          }, forceRequest);
        });
      });
    },

    login: function login(scope) {
      var fb = this;
      return this.init().then(function () {
        return new _ember["default"].RSVP.Promise(function (resolve, reject) {
          FB.login(function (response) {
            if (response.authResponse) {
              fb.accessToken = response.authResponse.accessToken;
              _ember["default"].run(null, resolve, response);
            } else {
              _ember["default"].run(null, reject, response);
            }
          }, { scope: scope });
        });
      });
    },

    loginWith: function loginWith(token) {
      this.accessToken = token;
      return token;
    },

    logout: function logout() {
      return this.init().then(function () {
        return new _ember["default"].RSVP.Promise(function (resolve) {
          FB.logout(function (response) {
            _ember["default"].run(null, resolve, response);
          });
        });
      });
    },

    getAuthResponse: function getAuthResponse() {
      return FB.getAuthResponse();
    }
  };

  exports["default"] = fb;
});